import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchorder } from "./Apiservice"; // Adjust the import path
import Swal from "sweetalert2"; // Import Swal for alerts

const Trackorder = () => {
  const [orders, setOrders] = useState([]); // Change 'favorites' to 'orders'
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const loadOrders = async () => {
      try {
        const data = await fetchorder(userId);
        console.log("Fetched orders:", data); // Log the fetched data
        // Check if the orders data is available and is an array
        if (data && Array.isArray(data.orders)) {
          setOrders(data.orders);
        } else {
          console.error("Fetched data is not valid:", data);
          setOrders([]); // Default to empty array if not valid
        }
      } catch (error) {
        console.error("Failed to load orders", error);
        setOrders([]); // Default to empty array on error
      } finally {
        setLoading(false);
      }
    };

    loadOrders();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2 className="text-center my-5">Order List</h2>

      <div className="shopping_cart_area mt-5">
        <div className="container">
          <form action="#">
            <div className="cart_page_inner mb-60">
              <div className="row">
                <div className="col-12">
                  <div className="cart_page_tabel">
                    <table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th className="text-center">Information</th>
                          <th>product detail</th>
                          <th>Price</th>
                          <th>Status</th>

                          <th>Track</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((order) =>
                          order.items.map((item) => (
                            <tr key={item.product_id} className="border-top">
                              <td>
                                <div className="cart_product_thumb">
                                  <img
                                    src={item.product_images[0]} // Adjust image path
                                    alt={item.product_name}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="cart_product_text">
                                  <h4>{item.product_name}</h4>
                                  <ul>
                                    <li>
                                      <i className="ion-ios-arrow-right"></i>
                                      Quantity: <span>{item.quantity}</span>
                                    </li>
                                    <li>
                                      <i className="ion-ios-arrow-right"></i>
                                      Size: <span>{item.selected_size}</span>
                                    </li>
                                    {/* Add more item details as needed */}
                                  </ul>
                                </div>
                              </td>
                              <td>
                                <div className="cart_product_remove text-center">
                                  <Link
                                    to={`/Productdetail/${item.product_id}`}
                                  >
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                    >
                                      View Product Detail
                                    </button>
                                  </Link>
                                </div>
                              </td>

                              <td>
                                <div className="cart_product_price">
                                  <span>₹{order.total_price}</span>
                                </div>
                              </td>
                              <td>
                                <div className="cart_product_price">
                                  <span>{order.order_status}</span>
                                </div>
                              </td>

                              <td>
                                <div className="cart_product_remove text-center">
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                  >
                                    Track your order
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Trackorder;
