import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchFavorites, removeFavorite } from "./Apiservice"; // Adjust the import path
import Swal from "sweetalert2"; // Import Swal for alerts

const Favourite = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const loadFavorites = async () => {
      try {
        const data = await fetchFavorites(userId);
        setFavorites(data);
      } catch (error) {
        console.error("Failed to load favorites", error);
      } finally {
        setLoading(false);
      }
    };

    loadFavorites();
  }, []);

  const handleRemoveFavorite = async (favoriteId) => {
    console.log("Attempting to remove favorite with ID:", favoriteId);

    try {
      await removeFavorite(favoriteId); // Pass only the favoriteId
      setFavorites((prevFavorites) =>
        prevFavorites.filter((favorite) => favorite.id !== favoriteId)
      );
      Swal.fire({
        icon: "success",
        title: "Removed",
        text: "Favorite removed successfully!",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Failed to remove favorite", error.response?.data || error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.error || "Failed to remove favorite.",
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* <nav aria-label="breadcrumb" id="headerspace">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Favourite List
          </li>
        </ol>
      </nav> */}

      <h2 className="text-center my-5">Favourite List</h2>

      <div className="shopping_cart_area mt-5">
        <div className="container">
          <form action="#">
            <div className="cart_page_inner mb-60">
              <div className="row">
                <div className="col-12">
                  <div className="cart_page_tabel">
                    <table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th className="text-center">Information</th>
                          <th>Price</th>
                          <th>place order</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {favorites.map((favorite) => (
                          <tr key={favorite.id} className="border-top">
                            <td>
                              <div className="cart_product_thumb">
                                <img
                                  src={favorite.product_images[0]} // Displaying the first image
                                  alt={favorite.product_name}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="cart_product_text">
                                <h4>{favorite.product_name}</h4>
                                <ul>
                                  <li>
                                    <i className="ion-ios-arrow-right"></i>{" "}
                                    Model height:{" "}
                                    <span>{favorite.model_height}</span>
                                  </li>
                                  <li>
                                    <i className="ion-ios-arrow-right"></i>{" "}
                                    Model wearing size:{" "}
                                    <span>{favorite.model_wearing_size}</span>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            <td>
                              <div className="cart_product_price">
                                <span>₹{favorite.product_price}</span>
                              </div>
                            </td>
                            <td>
                              <div className="cart_product_price">
                                <span>
                                  {" "}
                                  <Link
                                    to={`/Productdetail/${favorite.product}`}
                                  >
                                    <button
                                      className="btn btn-primary"
                                      type="submit"
                                    >
                                      Place order
                                    </button>
                                  </Link>
                                </span>
                              </div>
                            </td>

                            <td>
                              <div
                                className="cart_product_remove text-center"
                                onClick={() =>
                                  handleRemoveFavorite(favorite.id)
                                }
                              >
                                <button
                                  className="btn btn-danger"
                                  type="submit"
                                >
                                  Remove
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <hr></hr>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Favourite;
