import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const Minicart = ({ isOpen, onClose }) => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch cart details on component mount
  useEffect(() => {
    const fetchCartDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const userId = localStorage.getItem("userId");
        if (userId) {
          const response = await axios.get(
            `https://infiboutique.in/api/api/cart-fetch/${userId}/`
          );
          setCartItems(response.data.cart);
        } else {
          setError("User ID not found in local storage.");
        }
      } catch (err) {
        console.error(err);
        setError("Failed to fetch cart details.");
      } finally {
        setLoading(false);
      }
    };

    fetchCartDetails();
  }, []);

  // Handle remove item from cart
  const handleRemoveItem = async (cartItemId) => {
    try {
      await axios.delete(
        `https://infiboutique.in/api/api/cart-remove/${cartItemId}/`
      );
      setCartItems((prevItems) =>
        prevItems.filter((item) => item.cart_item_id !== cartItemId)
      );
      Swal.fire({
        icon: "success",
        title: "Removed",
        text: "Item removed successfully from cart!",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error("Error removing item from cart", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to remove item from cart.",
      });
    }
  };

  return (
    <div className={`minicart-header ${isOpen ? "show" : ""}`}>
      <div className="minicart-headermain">
        <div className="minicart-headermaintext">
          <h4>Cart</h4>
        </div>
        <div
          onClick={onClose}
          style={{ cursor: "pointer" }}
          className="minicart-headermainclose"
        >
          <i className="ion-android-close"></i>
        </div>
      </div>
      <hr />

      <div className="minicart-main row">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : cartItems.length > 0 ? (
          cartItems.map((item) => (
            <div
              className="minicart-inner d-flex justify-content-between"
              key={item.cart_item_id}
            >
              <div>
                <img src={item.images[0]} alt={item.product_name} />
              </div>
              <div>
                <p>{item.product_name}</p>
                <p>Price: ₹{item.price}</p>
                <p>Quantity: {item.quantity}</p>
              </div>
              <div>
                <button
                  className="btn btn-danger"
                  onClick={() => handleRemoveItem(item.cart_item_id)}
                >
                  <i className="ion-android-close"></i>
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Your cart is empty.</p>
        )}
      </div>

      <hr />

      <div className="place_order_btn">
        <Link className="btn btn-primary" to="/Cart" onClick={onClose}>
          View cart
        </Link>
      </div>
    </div>
  );
};

export default Minicart;
