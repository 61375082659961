import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { fetchcategorylist } from "./Apiservice";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import Notification from "./Notification";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { createFavorite } from "./Apiservice";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
import Loading from "./Loading";

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [bestsellerProducts, setBestsellerProducts] = useState([]);
  const [latestcollection, setLatestcollection] = useState([]);
  const [hoveredImages, setHoveredImages] = useState({});
  const [pageloading, setPageloading] = useState(true);

  useEffect(() => {
    const getCategories = async (id) => {
      try {
        const data = await fetchcategorylist(id);
        setCategories(data);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      } finally {
        setPageloading(false); // Set loading to false after data is fetched
      }
    };
    getCategories();
  }, []);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,

    speed: 500,
    infinite: true,
  };
  const settingscard = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    speed: 300,
    infinite: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 300, settings: { slidesToShow: 1 } },
    ],
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://infiboutique.in/api/api/featured-products/"
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);
  useEffect(() => {
    const fetchbestsellerProducts = async () => {
      try {
        const response = await axios.get(
          "https://infiboutique.in/api/api/best-sellers/"
        );
        setBestsellerProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchbestsellerProducts();
  }, []);
  useEffect(() => {
    const fetchlatestcollection = async () => {
      try {
        const response = await axios.get(
          "https://infiboutique.in/api/api/latest-collection/"
        );
        setLatestcollection(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchlatestcollection();
  }, []);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "Are these only Maternity wear Outfits?",
      answer:
        "No, all are normal outfits. Maternity zips are just an option to select as per customer choice.",
    },
    {
      question: "How to place an International Order?",
      answer:
        "International orders are taken through direct messages (DM). Send us your cart screenshot on Instagram to place an international order.",
    },
    {
      question: "Can you customize the outfits?",
      answer:
        "Only the length of the outfit can be customized as per the size chart. We do not take other customizations.",
    },
  ];

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [favorites, setFavorites] = useState([]);

  const toggleFavorite = (productId) => {
    if (favorites.includes(productId)) {
      setFavorites(favorites.filter((id) => id !== productId)); // Remove from favorites
    } else {
      setFavorites([...favorites, productId]); // Add to favorites
    }
  };
  const handleAddToFavorites = async (productId) => {
    const userId = localStorage.getItem("userId"); // Get userId from local storage
    if (userId) {
      try {
        await createFavorite(userId, productId);

        Swal.fire({
          icon: "success",
          title: "Add Cart",
          text: "Product added to favorites successfully",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add favorites.",
        });
        console.error("Error adding to favorites:", error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to add favorites.",
      });
    }
  };
  if (pageloading) {
    return <Loading />;
  }

  return (
    <div>
      <section className="slider_section mb-63">
        <Slider {...settings} className="slider_area">
          <div
            style={{
              backgroundImage:
                'url("https://ruffleberry.com/wp-content/uploads/2024/07/1.png")',
            }}
          >
            <div
              className="single_slider d-flex align-items-center"
              style={{
                backgroundImage:
                  'url("https://ruffleberry.com/wp-content/uploads/2024/07/2.png")',
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-7">
                    <div className="slider_text" style={{ color: "white" }}>
                      <span>Lookbook</span>
                      <h1>Fashion trend for autumn girls with vibes</h1>
                      <p style={{ color: "white" }}>
                        We love seeing how our Aslam wearers like <br /> to wear
                        their Norda
                      </p>
                      <Link className="btn btn-primary" to="/Contact">
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage:
                'url("https://ruffleberry.com/wp-content/uploads/2024/07/1.png")',
            }}
          >
            <div
              className="single_slider d-flex align-items-center"
              style={{
                backgroundImage:
                  'url("https://ruffleberry.com/wp-content/uploads/2024/07/2.png")',
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-7">
                    <div className="slider_text" style={{ color: "white" }}>
                      <span>Lookbook</span>
                      <h1>Fashion trend for autumn girls with vibes</h1>
                      <p style={{ color: "white" }}>
                        We love seeing how our Aslam wearers like <br /> to wear
                        their Norda
                      </p>
                      <Link className="btn btn-primary" to="/Contact">
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: 'url("./assets/img/slider/slider1.jpg")',
            }}
          >
            <div
              className="single_slider d-flex align-items-center"
              style={{
                backgroundImage:
                  'url("https://ruffleberry.com/wp-content/uploads/2024/07/2.png")',
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-7">
                    <div className="slider_text" style={{ color: "white" }}>
                      <span>Lookbook</span>
                      <h1>Fashion trend for autumn girls with vibes</h1>
                      <p style={{ color: "white" }}>
                        We love seeing how our Aslam wearers like <br /> to wear
                        their Norda
                      </p>
                      <Link className="btn btn-primary" to="/Contact">
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>Shop By Category</h2>
              </div>
            </div>
          </div>

          <div className="product_container row">
            {categories.map((category) => (
              <div className="col-lg-3" key={category.id}>
                <article className="single_product" id="Categorycard">
                  <figure>
                    <div className="product_thumb">
                      <Link to={`/product/${category.id}`}>
                        <img
                          className="primary_img"
                          src={category.image || "default-image.jpg"} // Default image fallback
                          alt={category.name}
                        />
                      </Link>
                    </div>
                    <figcaption className="product_content text-center">
                      <h4 className="product_name">
                        <Link to={`/category/${category.id}`}>
                          {category.name}
                        </Link>
                      </h4>
                    </figcaption>
                  </figure>
                </article>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>Our Latest Collection</h2>
              </div>
            </div>
          </div>

          <div className="product_container row">
            <Slider {...settingscard}>
              {latestcollection.map((product) => {
                // Ensure price is treated as a number
                const originalPrice = parseFloat(product.price);
                const hoveredImage =
                  hoveredImages[product.id] || product.images[0].image;
                // Calculate the discounted price
                const discountedPrice =
                  originalPrice -
                  (originalPrice * product.discount_percentage) / 100;

                return (
                  <div className="col-lg-3" key={product.id}>
                    <article className="single_product">
                      <figure>
                        <div
                          className="product_thumb"
                          onMouseEnter={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]:
                                product.images[1]?.image ||
                                product.images[0].image,
                            }))
                          }
                          onMouseLeave={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]: product.images[0].image,
                            }))
                          }
                        >
                          <Link to={`/Productdetail/${product.id}`}>
                            <img
                              className="primary_img"
                              src={hoveredImage}
                              alt={product.name}
                            />
                          </Link>
                          {product.discount_percentage > 0 && (
                            <div class="product_label">
                              <span>-{product.discount_percentage}%</span>
                            </div>
                          )}
                          <div className="product_action">
                            <ul>
                              <li
                                className="wishlist"
                                onClick={() => handleAddToFavorites(product.id)}
                              >
                                <Link
                                  to=""
                                  title="Wishlist"
                                  onClick={() => toggleFavorite(product.id)}
                                >
                                  {favorites.includes(product.id) ? (
                                    <FaHeart
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  ) : (
                                    <FaRegHeart
                                      style={{
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  )}
                                </Link>
                              </li>
                              <li className="quick_view">
                                <Link
                                  data-toggle="modal"
                                  data-target="#modal_box"
                                  title="Quick View"
                                  to="#"
                                  onClick={() => setSelectedProduct(product)}
                                >
                                  <i className="icon-size-fullscreen icons"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <figcaption className="product_content text-center">
                          <h4 className="product_name">
                            <Link to={`/Productdetail/${product.id}`}>
                              {product.name}
                            </Link>
                          </h4>

                          <div className="price_box">
                            <span className="current_price">
                              {discountedPrice.toFixed(2)}
                            </span>
                            {product.discount_percentage > 0 && (
                              <span className="old_price">
                                {originalPrice.toFixed(2)}
                              </span>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="product_container row">
            <Slider {...settingscard}>
              {[...latestcollection].reverse().map((product) => {
                // Ensure price is treated as a number
                const originalPrice = parseFloat(product.price);
                const hoveredImage =
                  hoveredImages[product.id] || product.images[0].image;
                // Calculate the discounted price
                const discountedPrice =
                  originalPrice -
                  (originalPrice * product.discount_percentage) / 100;

                return (
                  <div className="col-lg-3" key={product.id}>
                    <article className="single_product">
                      <figure>
                        <div
                          className="product_thumb"
                          onMouseEnter={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]:
                                product.images[1]?.image ||
                                product.images[0].image,
                            }))
                          }
                          onMouseLeave={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]: product.images[0].image,
                            }))
                          }
                        >
                          <Link to={`/Productdetail/${product.id}`}>
                            <img
                              className="primary_img"
                              src={hoveredImage}
                              alt={product.name}
                            />
                          </Link>
                          {product.discount_percentage > 0 && (
                            <div class="product_label">
                              <span>-{product.discount_percentage}%</span>
                            </div>
                          )}
                          <div className="product_action">
                            <ul>
                              <li
                                className="wishlist"
                                onClick={() => handleAddToFavorites(product.id)}
                              >
                                <Link
                                  to=""
                                  title="Wishlist"
                                  onClick={() => toggleFavorite(product.id)}
                                >
                                  {favorites.includes(product.id) ? (
                                    <FaHeart
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  ) : (
                                    <FaRegHeart
                                      style={{
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  )}
                                </Link>
                              </li>
                              <li className="quick_view">
                                <Link
                                  data-toggle="modal"
                                  data-target="#modal_box"
                                  title="Quick View"
                                  to="#"
                                  onClick={() => setSelectedProduct(product)}
                                >
                                  <i className="icon-size-fullscreen icons"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <figcaption className="product_content text-center">
                          <h4 className="product_name">
                            <Link to={`/Productdetail/${product.id}`}>
                              {product.name}
                            </Link>
                          </h4>

                          <div className="price_box">
                            <span className="current_price">
                              {discountedPrice.toFixed(2)}
                            </span>
                            {product.discount_percentage > 0 && (
                              <span className="old_price">
                                {originalPrice.toFixed(2)}
                              </span>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
      <section className="accordion">
        <div className="accordion-left">
          <h1>FAQ</h1>
          <p>
            Check out the most common questions our customers ask. If you have
            more questions, please contact our customer support.
          </p>
        </div>
        <div className="accordion-right">
          {faqItems.map((item, index) => (
            <div key={index} className="accordion-item">
              <button
                className="accordion-header"
                onClick={() => toggleAccordion(index)}
              >
                <h3>{item.question}</h3>
              </button>
              <div
                className={`accordion-content ${
                  openIndex === index ? "open" : ""
                }`}
              >
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>Best Seller Products</h2>
              </div>
            </div>
          </div>

          <div className="product_container row">
            <Slider {...settingscard}>
              {bestsellerProducts.map((product) => {
                // Ensure price is treated as a number
                const originalPrice = parseFloat(product.price);
                const hoveredImage =
                  hoveredImages[product.id] || product.images[0].image;
                // Calculate the discounted price
                const discountedPrice =
                  originalPrice -
                  (originalPrice * product.discount_percentage) / 100;

                return (
                  <div className="col-lg-3" key={product.id}>
                    <article className="single_product">
                      <figure>
                        <div
                          className="product_thumb"
                          onMouseEnter={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]:
                                product.images[1]?.image ||
                                product.images[0].image,
                            }))
                          }
                          onMouseLeave={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]: product.images[0].image,
                            }))
                          }
                        >
                          <Link to={`/Productdetail/${product.id}`}>
                            <img
                              className="primary_img"
                              src={hoveredImage}
                              alt={product.name}
                            />
                          </Link>
                          {product.discount_percentage > 0 && (
                            <div class="product_label">
                              <span>-{product.discount_percentage}%</span>
                            </div>
                          )}
                          <div className="product_action">
                            <ul>
                              <li
                                className="wishlist"
                                onClick={() => handleAddToFavorites(product.id)}
                              >
                                <Link
                                  to=""
                                  title="Wishlist"
                                  onClick={() => toggleFavorite(product.id)}
                                >
                                  {favorites.includes(product.id) ? (
                                    <FaHeart
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  ) : (
                                    <FaRegHeart
                                      style={{
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  )}
                                </Link>
                              </li>
                              <li className="quick_view">
                                <Link
                                  data-toggle="modal"
                                  data-target="#modal_box"
                                  title="Quick View"
                                  to="#"
                                  onClick={() => setSelectedProduct(product)}
                                >
                                  <i className="icon-size-fullscreen icons"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <figcaption className="product_content text-center">
                          <h4 className="product_name">
                            <Link to={`/Productdetail/${product.id}`}>
                              {product.name}
                            </Link>
                          </h4>

                          <div className="price_box">
                            <span className="current_price">
                              {discountedPrice.toFixed(2)}
                            </span>
                            {product.discount_percentage > 0 && (
                              <span className="old_price">
                                {originalPrice.toFixed(2)}
                              </span>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>

          <div className="product_container row">
            <Slider {...settingscard}>
              {[...bestsellerProducts].reverse().map((product) => {
                // Ensure price is treated as a number
                const originalPrice = parseFloat(product.price);
                const hoveredImage =
                  hoveredImages[product.id] || product.images[0].image;
                // Calculate the discounted price
                const discountedPrice =
                  originalPrice -
                  (originalPrice * product.discount_percentage) / 100;

                return (
                  <div className="col-lg-3" key={product.id}>
                    <article className="single_product">
                      <figure>
                        <div
                          className="product_thumb"
                          onMouseEnter={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]:
                                product.images[1]?.image ||
                                product.images[0].image,
                            }))
                          }
                          onMouseLeave={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]: product.images[0].image,
                            }))
                          }
                        >
                          <Link to={`/Productdetail/${product.id}`}>
                            <img
                              className="primary_img"
                              src={hoveredImage}
                              alt={product.name}
                            />
                          </Link>
                          {product.discount_percentage > 0 && (
                            <div class="product_label">
                              <span>-{product.discount_percentage}%</span>
                            </div>
                          )}
                          <div className="product_action">
                            <ul>
                              <li
                                className="wishlist"
                                onClick={() => handleAddToFavorites(product.id)}
                              >
                                <Link
                                  to=""
                                  title="Wishlist"
                                  onClick={() => toggleFavorite(product.id)}
                                >
                                  {favorites.includes(product.id) ? (
                                    <FaHeart
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  ) : (
                                    <FaRegHeart
                                      style={{
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  )}
                                </Link>
                              </li>
                              <li className="quick_view">
                                <Link
                                  data-toggle="modal"
                                  data-target="#modal_box"
                                  title="Quick View"
                                  to="#"
                                  onClick={() => setSelectedProduct(product)}
                                >
                                  <i className="icon-size-fullscreen icons"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <figcaption className="product_content text-center">
                          <h4 className="product_name">
                            <Link to={`/Productdetail/${product.id}`}>
                              {product.name}
                            </Link>
                          </h4>

                          <div className="price_box">
                            <span className="current_price">
                              {discountedPrice.toFixed(2)}
                            </span>
                            {product.discount_percentage > 0 && (
                              <span className="old_price">
                                {originalPrice.toFixed(2)}
                              </span>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>

      <section className="testmonial">
        <div className="testmonial-text">
          <h1>Client Testimonials</h1>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            navigation={false}
            autoplay={{ delay: 3000 }}
            loop={true}
            modules={[Pagination, Navigation, Autoplay]}
          >
            <SwiperSlide>
              <div>
                <p>
                  "The attention to detail and exceptional service provided by
                  this boutique is unmatched. I absolutely love my purchase and
                  will be a returning customer!"
                </p>
                <h5>Rajalakshmi</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <p>
                  "I had a fantastic experience shopping here. The staff was
                  incredibly helpful, and I found the perfect outfit for my
                  event. Highly recommended!"
                </p>
                <h5>Priya</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <p>
                  "This boutique offers a unique selection of items that you
                  won't find anywhere else. I'm so glad I stumbled upon this
                  gem!"
                </p>
                <h5>Lakshmi</h5>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>featured Products</h2>
              </div>
            </div>
          </div>

          <div className="product_container row">
            <Slider {...settingscard}>
              {products.map((product) => {
                // Ensure price is treated as a number
                const originalPrice = parseFloat(product.price);
                const hoveredImage =
                  hoveredImages[product.id] || product.images[0].image;
                // Calculate the discounted price
                const discountedPrice =
                  originalPrice -
                  (originalPrice * product.discount_percentage) / 100;

                return (
                  <div className="col-lg-3" key={product.id}>
                    <article className="single_product">
                      <figure>
                        <div
                          className="product_thumb"
                          onMouseEnter={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]:
                                product.images[1]?.image ||
                                product.images[0].image,
                            }))
                          }
                          onMouseLeave={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]: product.images[0].image,
                            }))
                          }
                        >
                          <Link to={`/Productdetail/${product.id}`}>
                            <img
                              className="primary_img"
                              src={hoveredImage}
                              alt={product.name}
                            />
                          </Link>
                          {product.discount_percentage > 0 && (
                            <div class="product_label">
                              <span>-{product.discount_percentage}%</span>
                            </div>
                          )}
                          <div className="product_action">
                            <ul>
                              <li
                                className="wishlist"
                                onClick={() => handleAddToFavorites(product.id)}
                              >
                                <Link
                                  to=""
                                  title="Wishlist"
                                  onClick={() => toggleFavorite(product.id)}
                                >
                                  {favorites.includes(product.id) ? (
                                    <FaHeart
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  ) : (
                                    <FaRegHeart
                                      style={{
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  )}
                                </Link>
                              </li>
                              <li className="quick_view">
                                <Link
                                  data-toggle="modal"
                                  data-target="#modal_box"
                                  title="Quick View"
                                  to="#"
                                  onClick={() => setSelectedProduct(product)}
                                >
                                  <i className="icon-size-fullscreen icons"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <figcaption className="product_content text-center">
                          <h4 className="product_name">
                            <Link to={`/Productdetail/${product.id}`}>
                              {product.name}
                            </Link>
                          </h4>

                          <div className="price_box">
                            <span className="current_price">
                              {discountedPrice.toFixed(2)}
                            </span>
                            {product.discount_percentage > 0 && (
                              <span className="old_price">
                                {originalPrice.toFixed(2)}
                              </span>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>

          <div className="product_container row">
            <Slider {...settingscard}>
              {[...products].reverse().map((product) => {
                // Ensure price is treated as a number
                const originalPrice = parseFloat(product.price);
                const hoveredImage =
                  hoveredImages[product.id] || product.images[0].image;
                // Calculate the discounted price
                const discountedPrice =
                  originalPrice -
                  (originalPrice * product.discount_percentage) / 100;

                return (
                  <div className="col-lg-3" key={product.id}>
                    <article className="single_product">
                      <figure>
                        <div
                          className="product_thumb"
                          onMouseEnter={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]:
                                product.images[1]?.image ||
                                product.images[0].image,
                            }))
                          }
                          onMouseLeave={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]: product.images[0].image,
                            }))
                          }
                        >
                          <Link to={`/Productdetail/${product.id}`}>
                            <img
                              className="primary_img"
                              src={hoveredImage}
                              alt={product.name}
                            />
                          </Link>
                          {product.discount_percentage > 0 && (
                            <div class="product_label">
                              <span>-{product.discount_percentage}%</span>
                            </div>
                          )}
                          <div className="product_action">
                            <ul>
                              <li
                                className="wishlist"
                                onClick={() => handleAddToFavorites(product.id)}
                              >
                                <Link
                                  to=""
                                  title="Wishlist"
                                  onClick={() => toggleFavorite(product.id)}
                                >
                                  {favorites.includes(product.id) ? (
                                    <FaHeart
                                      style={{
                                        color: "red",
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  ) : (
                                    <FaRegHeart
                                      style={{
                                        fontSize: "20px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  )}
                                </Link>
                              </li>
                              <li className="quick_view">
                                <Link
                                  data-toggle="modal"
                                  data-target="#modal_box"
                                  title="Quick View"
                                  to="#"
                                  onClick={() => setSelectedProduct(product)}
                                >
                                  <i className="icon-size-fullscreen icons"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <figcaption className="product_content text-center">
                          <h4 className="product_name">
                            <Link to={`/Productdetail/${product.id}`}>
                              {product.name}
                            </Link>
                          </h4>

                          <div className="price_box">
                            <span className="current_price">
                              {discountedPrice.toFixed(2)}
                            </span>
                            {product.discount_percentage > 0 && (
                              <span className="old_price">
                                {originalPrice.toFixed(2)}
                              </span>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="modal_box"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="ion-android-close"></i>
              </span>
            </button>
            {selectedProduct && (
              <div className="modal_body">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="modal_zoom_gallery">
                        <div className="big_image_container">
                          <img
                            id="bigImage"
                            src={selectedProduct.images[0].image} // Use selected product image
                            alt={selectedProduct.name}
                            className="big_image"
                          />
                        </div>
                        <div className="small_images_container">
                          {selectedProduct.images.map((img, index) => (
                            <img
                              key={index}
                              src={img.image}
                              alt={selectedProduct.name}
                              className="small_image"
                              onClick={() =>
                                (document.getElementById("bigImage").src =
                                  img.image)
                              }
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="modal_right">
                        <div className="modal_title mb-10">
                          <h2>{selectedProduct.name}</h2>
                        </div>
                        <div className="modal_price mb-10 d-flex  align-items-center g-3">
                          <h5>Price</h5>
                          <span className="new_price ml-3">
                            {selectedProduct.price}
                          </span>{" "}
                        </div>
                        <div className="modal_description mb-15 d-flex  align-items-center g-3">
                          <h5>description</h5>
                          <p className="ml-3">{selectedProduct.description}</p>
                        </div>
                        <div className="modal_description mb-15 d-flex  align-items-center g-3">
                          <h5>fabric</h5>
                          <p className="ml-3">{selectedProduct.fabric}</p>
                        </div>
                        <div className="modal_description mb-15 d-flex  align-items-center g-3">
                          <h5>style</h5>
                          <p className="ml-3">{selectedProduct.style}</p>
                        </div>
                        <div className="modal_description mb-15 d-flex  align-items-center g-3">
                          <h5>Model wearing height</h5>
                          <p className="ml-3">{selectedProduct.model_height}</p>
                        </div>
                        <div className="modal_description mb-15 d-flex  align-items-center g-3">
                          <h5>Model wearing size</h5>
                          <p className="ml-3">
                            {selectedProduct.model_wearing_size}
                          </p>
                        </div>

                        <button
                          className="button btn btn-success py-2"
                          type="button"
                        >
                          Go to Product detail page
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <section class="banner_section mb-109">
        <div class="container">
          <div class="section_title mb-60">
            <h2>featured collections</h2>
          </div>
          <div class="banner_container d-flex">
            <figure class="single_banner position-relative mr-30">
              <img
                src="https://ruffleberry.com/wp-content/uploads/2023/12/DGP02369-700x933.jpg"
                alt=""
              />
              <figcaption class="banner_text position-absolute">
                <h3>
                  Zara Pattern <br></br>backpacks
                </h3>
                <p>
                  Stretch, fresh-cool help you alway <br></br> comfortable
                </p>
                <Link class="btn btn-primary" to="/product">
                  Shop Now
                </Link>
              </figcaption>
            </figure>
            <figure class="single_banner position-relative">
              <img
                src="	https://ruffleberry.com/wp-content/uploads/2023/10/IMG_7203-500x667.jpg"
                alt=""
              />
              <figcaption class="banner_text position-absolute">
                <h3 class="text-white">Basic Color Caps</h3>
                <p class="text-white">
                  Minimalist never cool, choose and make <br></br> the simple
                  great again!
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <Notification />
    </div>
  );
};

export default Home;
